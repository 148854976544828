import React from 'react'
import "./Slider.css"
import AwesomeSlider from 'react-awesome-slider'



const Slider = () => {
    return (
        <>
        
        <div  className="imagenes-container" >
            <h1 className="galeria-titulo">Galeria de Fotos</h1>
            <h3 >Click  para agrandar</h3>
            <img className="imagen-galeria" src = "https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/Slides%2Fanochecer.png?alt=media&token=46fa1496-69e7-492b-92cf-90d77394dece"/>
            <img className="imagen-galeria" src = "https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/Slides%2FHuaraz.png?alt=media&token=186bcf80-ca74-4450-8abb-cbdf0a15e830"/>
            <img className="imagen-galeria" src = "https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/Slides%2FHuari.png?alt=media&token=8e6312f7-e2cb-4c58-990f-3bfda3566e7b"/>
            <img className="imagen-galeria" src = "https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/Slides%2FPastorita.png?alt=media&token=179cba67-8b82-4324-8a67-f00074da54a2"/>
            <img className="imagen-galeria" src = "https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/Slides%2FPinturaAndina.png?alt=media&token=f74ac03d-76cc-441c-a80d-0af1b4d9ce5c"/>
            <img className="imagen-galeria" src = "https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/Slides%2FPlaza.png?alt=media&token=db0f774a-7dc9-45fb-83a2-263f2e401c9e"/>
            <img className="imagen-galeria" src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/Slides%2FPlazadeArmas.png?alt=media&token=2613db50-0f96-45ca-912a-ece3fde0324f"/>
            <img className="imagen-galeria" src = "https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/Slides%2FRiachuelo.png?alt=media&token=a7294c93-f7ed-4c61-acf9-9c9397d5e724"/>

        </div>

        </>
    )
}

export default Slider;
