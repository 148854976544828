import React from 'react'
import "./Footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className = "footer-info">
                <h1>Radio Huaraz</h1> 
                <p>Transmitiendo la mejor musica</p>            
            </div>
            <div className="footer-contact">
                <h3>Contactanos</h3>
                <p>En nuestras redes sociales</p>
            </div>    
            <div className ="footer-contact2">    
                <h3>Asesoria Legal</h3>
                <p> Apoyo tecnico</p>
                
            </div>
            <div className ="footer-sns">
                <div className="design-by">
                    Design by Waras
                </div>
                <div className="sns-links" > 
                    <a href="https://play.google.com/store/apps/details?id=com.cositradios.radiohuaraz">
                        <i className="fab fa-android" target="_blank" rel="noreferrer" ></i>
                    </a>
                    <a href="https://www.youtube.com/channel/UCvnGccai9sgU24rNCndWvgw">
                        <i className="fab fa-youtube youtube" target="_blank" rel="noreferrer" ></i>
                    </a>
                    <a href="https://www.facebook.com/RadioHuaraz/" target="_blank" rel="noreferrer">
                        <i className = "fab fa-facebook facebook"></i>
                    </a>
                    <a href="https://wa.link/i1m9n8" >
                        <i className="fab fa-whatsapp" target="blank" rel="noreferrer"></i>
                        
                    </a>
                </div>   
                


            </div>
       </footer>
       )
}

export default Footer
