import React from 'react';
import "./Navbar.css";
import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const Navbar = ({isScrolling}) => {

    const toThetop = () =>{
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }
    return (
        <nav className={`navbar ${isScrolling > 20 ? "scrolling" : null }`}><div className="navbar-logo" onClick={toThetop}>Radio Huaraz
        </div><AudioPlayer
        style={{
            width: '600px'
        }}
        showJumpControls={false}
        customAdditionalControls={[]}
        
        className="player"
        autoPlay
        src="https://radio.sistemasandinos.org/7374/stream"
        onPlay={e => console.log("onPlay")}
// other props here
/>
            
            <div className="player">
             
            </div>
        
            

            
        </nav>
    )
}

export default Navbar
