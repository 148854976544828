import React from 'react';
import "./Programacion.css";
import imagenProgramacion from "../../media/images/imagen-programacion.png"

const Programacion = () => {
    return (
        <div className="programacion-container">
            <div className ="programacion-img">
                <img src = {imagenProgramacion} alt ="programacion"/>
                
            </div>
            <div className="programacion-desc">
                <a href="https://www.facebook.com/RadioHuaraz/videos/?ref=page_internal" className="programacion-text">Programación: (Click para ver nuestras transmisiones)</a>
                <h4>6am. a 9am.</h4>
                <p>Amanecer Ancashino</p>
                <h4>9am. a 11am.</h4>
                <p>Cumbia de todos los tiempos</p>
                <h4>11am. a 1pm.</h4>
                <p>La hora familiar</p>
                <h4>1pm. a 3pm.</h4>
                <p>Pasillo a pasillo</p>
                <h4>3pm. a 5pm.</h4>
                <p>Rock en Ingles y en Español</p>
                <h4>5pm. a 7pm.</h4>
                <p>Corazon Andino</p>
                <h4>7pm. a 10pm.</h4>
                <p>Música variada</p>
            </div>
            
            
        </div>
    )
}

export default Programacion;
