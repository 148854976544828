import React from 'react'
import "./Contador.css"

const Contador = () => {
    return (
       <div className="contador-container">
           Contador de visitas
           <img className="contador" src="http://www.websmultimedia.com/contador-de-visitas.php?id=304595"/>
                       
        </div>
        

        
    )
}

export default Contador
