import React from 'react';
import "./About.css";


const About = () => {
    return (
        <div className="about-container">
            <div className="about-desc">
                <h3 className="titulo-about">Acerca de Nosotros</h3>
                <p>Somos una radio comprometida con nuestro quehacer regional y vamos al rescate y promocion de lo nuestro, música, historia, costumbres, tradiciones y mucho más.</p>
                
                <h3 className="titulo-about">Nuestra Mision</h3>
                <p>Nuestra misión es llegar con una programacion local, regional, y nacional siempre imponiendo nuestra identidad como región, cultura, costumbres, historia, unir a nuestros hermanos Ancashinos asentados en diversas ciudades del Perú y el mundo</p>
            </div>
            <div className ="about-img">
                <img src = "https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/Slides%2Facerca-de-imagen.jpg?alt=media&token=1e16e96f-2260-4080-a39b-8adae6ee6e3b" alt ="about"/>
            </div> 
                <img src = "https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/Slides%2Facerca-de-imagen2.jpg?alt=media&token=e0f38dcf-7a20-45b2-a50a-e6276823b450" alt="about2"/>
            <div className ="about-img2">   
            </div>
            
        </div>
    )
}

export default About
