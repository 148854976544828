import styled from "styled-components";

export default styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;  
  width: 100%;
  height: 100%;
  background-color: black;
  color: blue;
  margin: 20px;
  font-size: 2em;
`;
