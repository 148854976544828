import React from 'react';
import "./cover.css";
import imagenFondo from "../../media/images/imagenFondo.png";
import logoRadio from "../../media/images/logoRadio.png"


const Cover = () => {
    return(  
    <div className="cover-container">
            <img className="logoRadio" src = {logoRadio}/>
            <img className="imagen" src={imagenFondo}/>
            <h1>Radio Huaraz</h1>
            <p>Música | Cultura | Entretenimiento</p>            
            <h3>Contacto:</h3>
            
            <p>945 118 687</p>
            <div>
            <a href="https://wa.link/i1m9n8" >
                        <i className="fab fa-whatsapp" target="blank" rel="noreferrer"></i>
                        </a>
            </div>
            



            
        </div>
    );
    
};


export default Cover
