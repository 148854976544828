import React, {useState, useEffect} from 'react';

import Item from "./Item";
import Carousel from 'react-elastic-carousel';

import './App.css';
import Cover from './components/cover/Cover';
import Navbar from './components/navbar/Navbar';
import About from './components/about/About';
import Slider from './components/slider/Slider';
import Footer from './components/footer/Footer';
import Programacion from './components/programacion/Programacion';
import Contador from './components/contador/Contador';





function App() {
  const [scrollHeight, setScrollHeight] = useState(0);

  const handleScroll = () =>{
    const position = window.pageYOffset;
    setScrollHeight(position);

  };
  

  useEffect( () =>{
    window.addEventListener("scroll", handleScroll);
  }, [scrollHeight])

  //Funcion de Carrusel

  const carouselRef = React.useRef(null);
const onNextStart = (currentItem, nextItem) => {
  if (currentItem.index === nextItem.index) {
    // we hit the last item, go to first item
    carouselRef.current.goTo(0);
  }
};
const onPrevStart = (currentItem, nextItem) => {
  if (currentItem.index === nextItem.index) {
    // we hit the first item, go to last item
    carouselRef.current.goTo(8);
  }
};
  

  return (
    <div className="App">
      <Navbar isScrolling = {scrollHeight} />
      <Cover/>
      <Carousel
          enableAutoPlay autoPlaySpeed={4000}
          itemsToShow={2}
          breakPoints={[
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 1, itemsToScroll: 2, pagination: false },
            { width: 850, itemsToShow: 1 },
            { width: 1150, itemsToShow: 2, itemsToScroll: 2 },
            { width: 1450, itemsToShow: 3 },
            { width: 1750, itemsToShow: 4 }]}
          disableArrowsOnEnd={false}
          easing="cubic-bezier(1,.15,.55,1.54)"
          tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
          transitionMs={900}
          className="Carrusel"
          >
        <Item maxWidth="50%"> <img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2FStudio7.png?alt=media&token=914241a7-ec68-47e9-8fc4-8bb4a3b67cd5" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fabogado.png?alt=media&token=ff997db5-5bd6-4b3e-9cb9-43d227571c63" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Famanecer.png?alt=media&token=3e77f4a6-24f0-4a1f-85d6-3ccfc6086025" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fargenper.png?alt=media&token=4699f4c0-7ea9-413a-a396-bafdad2b3721" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fconfecciones.png?alt=media&token=adaf4443-2c9e-4b18-8f6d-f29a7c7e87a1" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Ftaxirey.png?alt=media&token=07d1bffb-97f4-42b1-a9c3-f30e6aa9063d" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fwaras.png?alt=media&token=19a8eaec-8a4e-4b97-83d2-85b133df9fff" /></Item>
        <Item> <img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2FStudio7.png?alt=media&token=914241a7-ec68-47e9-8fc4-8bb4a3b67cd5" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fabogado.png?alt=media&token=ff997db5-5bd6-4b3e-9cb9-43d227571c63" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Famanecer.png?alt=media&token=3e77f4a6-24f0-4a1f-85d6-3ccfc6086025" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fargenper.png?alt=media&token=4699f4c0-7ea9-413a-a396-bafdad2b3721" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fconfecciones.png?alt=media&token=adaf4443-2c9e-4b18-8f6d-f29a7c7e87a1" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Ftaxirey.png?alt=media&token=07d1bffb-97f4-42b1-a9c3-f30e6aa9063d" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fwaras.png?alt=media&token=19a8eaec-8a4e-4b97-83d2-85b133df9fff" /></Item>
        <Item> <img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2FStudio7.png?alt=media&token=914241a7-ec68-47e9-8fc4-8bb4a3b67cd5" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fabogado.png?alt=media&token=ff997db5-5bd6-4b3e-9cb9-43d227571c63" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Famanecer.png?alt=media&token=3e77f4a6-24f0-4a1f-85d6-3ccfc6086025" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fargenper.png?alt=media&token=4699f4c0-7ea9-413a-a396-bafdad2b3721" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fconfecciones.png?alt=media&token=adaf4443-2c9e-4b18-8f6d-f29a7c7e87a1" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Ftaxirey.png?alt=media&token=07d1bffb-97f4-42b1-a9c3-f30e6aa9063d" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fwaras.png?alt=media&token=19a8eaec-8a4e-4b97-83d2-85b133df9fff" /></Item>
        <Item> <img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2FStudio7.png?alt=media&token=914241a7-ec68-47e9-8fc4-8bb4a3b67cd5" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fabogado.png?alt=media&token=ff997db5-5bd6-4b3e-9cb9-43d227571c63" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Famanecer.png?alt=media&token=3e77f4a6-24f0-4a1f-85d6-3ccfc6086025" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fargenper.png?alt=media&token=4699f4c0-7ea9-413a-a396-bafdad2b3721" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fconfecciones.png?alt=media&token=adaf4443-2c9e-4b18-8f6d-f29a7c7e87a1" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Ftaxirey.png?alt=media&token=07d1bffb-97f4-42b1-a9c3-f30e6aa9063d" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fwaras.png?alt=media&token=19a8eaec-8a4e-4b97-83d2-85b133df9fff" /></Item>
        <Item> <img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2FStudio7.png?alt=media&token=914241a7-ec68-47e9-8fc4-8bb4a3b67cd5" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fabogado.png?alt=media&token=ff997db5-5bd6-4b3e-9cb9-43d227571c63" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Famanecer.png?alt=media&token=3e77f4a6-24f0-4a1f-85d6-3ccfc6086025" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fargenper.png?alt=media&token=4699f4c0-7ea9-413a-a396-bafdad2b3721" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fconfecciones.png?alt=media&token=adaf4443-2c9e-4b18-8f6d-f29a7c7e87a1" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Ftaxirey.png?alt=media&token=07d1bffb-97f4-42b1-a9c3-f30e6aa9063d" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fwaras.png?alt=media&token=19a8eaec-8a4e-4b97-83d2-85b133df9fff" /></Item>
        <Item> <img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2FStudio7.png?alt=media&token=914241a7-ec68-47e9-8fc4-8bb4a3b67cd5" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fabogado.png?alt=media&token=ff997db5-5bd6-4b3e-9cb9-43d227571c63" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Famanecer.png?alt=media&token=3e77f4a6-24f0-4a1f-85d6-3ccfc6086025" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fargenper.png?alt=media&token=4699f4c0-7ea9-413a-a396-bafdad2b3721" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fconfecciones.png?alt=media&token=adaf4443-2c9e-4b18-8f6d-f29a7c7e87a1" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Ftaxirey.png?alt=media&token=07d1bffb-97f4-42b1-a9c3-f30e6aa9063d" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fwaras.png?alt=media&token=19a8eaec-8a4e-4b97-83d2-85b133df9fff" /></Item>
        <Item> <img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2FStudio7.png?alt=media&token=914241a7-ec68-47e9-8fc4-8bb4a3b67cd5" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fabogado.png?alt=media&token=ff997db5-5bd6-4b3e-9cb9-43d227571c63" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Famanecer.png?alt=media&token=3e77f4a6-24f0-4a1f-85d6-3ccfc6086025" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fargenper.png?alt=media&token=4699f4c0-7ea9-413a-a396-bafdad2b3721" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fconfecciones.png?alt=media&token=adaf4443-2c9e-4b18-8f6d-f29a7c7e87a1" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Ftaxirey.png?alt=media&token=07d1bffb-97f4-42b1-a9c3-f30e6aa9063d" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fwaras.png?alt=media&token=19a8eaec-8a4e-4b97-83d2-85b133df9fff" /></Item>
        <Item> <img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2FStudio7.png?alt=media&token=914241a7-ec68-47e9-8fc4-8bb4a3b67cd5" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fabogado.png?alt=media&token=ff997db5-5bd6-4b3e-9cb9-43d227571c63" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Famanecer.png?alt=media&token=3e77f4a6-24f0-4a1f-85d6-3ccfc6086025" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fargenper.png?alt=media&token=4699f4c0-7ea9-413a-a396-bafdad2b3721" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fconfecciones.png?alt=media&token=adaf4443-2c9e-4b18-8f6d-f29a7c7e87a1" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Ftaxirey.png?alt=media&token=07d1bffb-97f4-42b1-a9c3-f30e6aa9063d" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fwaras.png?alt=media&token=19a8eaec-8a4e-4b97-83d2-85b133df9fff" /></Item>
        <Item> <img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2FStudio7.png?alt=media&token=914241a7-ec68-47e9-8fc4-8bb4a3b67cd5" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fabogado.png?alt=media&token=ff997db5-5bd6-4b3e-9cb9-43d227571c63" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Famanecer.png?alt=media&token=3e77f4a6-24f0-4a1f-85d6-3ccfc6086025" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fargenper.png?alt=media&token=4699f4c0-7ea9-413a-a396-bafdad2b3721" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fconfecciones.png?alt=media&token=adaf4443-2c9e-4b18-8f6d-f29a7c7e87a1" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Ftaxirey.png?alt=media&token=07d1bffb-97f4-42b1-a9c3-f30e6aa9063d" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fwaras.png?alt=media&token=19a8eaec-8a4e-4b97-83d2-85b133df9fff" /></Item>
        <Item> <img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2FStudio7.png?alt=media&token=914241a7-ec68-47e9-8fc4-8bb4a3b67cd5" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fabogado.png?alt=media&token=ff997db5-5bd6-4b3e-9cb9-43d227571c63" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Famanecer.png?alt=media&token=3e77f4a6-24f0-4a1f-85d6-3ccfc6086025" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fargenper.png?alt=media&token=4699f4c0-7ea9-413a-a396-bafdad2b3721" /> </Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fconfecciones.png?alt=media&token=adaf4443-2c9e-4b18-8f6d-f29a7c7e87a1" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Ftaxirey.png?alt=media&token=07d1bffb-97f4-42b1-a9c3-f30e6aa9063d" /></Item>
        <Item><img src ="https://firebasestorage.googleapis.com/v0/b/radio-huaraz.appspot.com/o/banners%2Fwaras.png?alt=media&token=19a8eaec-8a4e-4b97-83d2-85b133df9fff" /></Item>
        
      </Carousel>
      

      <About/>
      
      <Programacion/>
      <Slider/>
      <Contador/>      
       
       <Footer/>
      

    </div>
  );
}

export default App;
